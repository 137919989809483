import React from 'react'
import { graphql, Link } from 'gatsby'
import Image from 'gatsby-image'
import { Container, Row, Col } from 'react-bootstrap'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Section from '../components/section'

const IndexPage = ({ data }) => {
  const socialImage = 'https://www.chrisdpeters.com/images/social/chrisdpeters-summary.png'
  const socialImageAlt = `Chris Peters`

  const description = `
    With over 20 years of experience, I help plan, execute, and optimize digital
    experiences.
  `

  const portfolio = data.portfolio.nodes[0]

  return (
    <Layout>
      <SEO
        title="Digital Creativity and Strategy"
        description={description}

        meta={[
          {
            property: 'og:image',
            content: socialImage,
          },
          {
            property: 'og:image:alt',
            content: socialImageAlt,
          },
          {
            name: 'twitter:creator',
            content: '@chrisdpeters',
          },
          {
            name: 'twitter:card',
            content: 'summary',
          },
          {
            name: 'twitter:image',
            content: socialImage,
          },
          {
            name: 'twitter:image:alt',
            content: socialImageAlt,
          },
        ]}
      >
        <link
          rel="canonical"
          href={`${data.site.siteMetadata.siteUrl}/`}
        />
      </SEO>

      <section className="hero">
        <Container>
          <Row>
            <Col>
              <h1 className="hero-headline">
                <small className="hero-small">Digital</small>{' '}
                <strong className="hero-accent">Creativity</strong>{' '}
                &amp; Strategy
              </h1>

              <figure className="hero-avatar">
                <Image
                  fixed={[
                    data.avatar.childImageSharp.xs,
                    {
                      ...data.avatar.childImageSharp.lg,
                      media: `(min-width: 992px) and (max-width: 1199px)`,
                    },
                    {
                      ...data.avatar.childImageSharp.xl,
                      media: `(min-width: 1200px)`,
                    },
                  ]}
                  alt="Chris Peters"
                  className="hero-image d-none d-sm-block"
                />

                <figcaption className="hero-desc">
                  {description}
                </figcaption>
              </figure>
            </Col>
          </Row>
        </Container>
      </section>

      <Section>
        <Container>
          <Row>
            <Col md={5} lg={4}>
              <h2 className="heading-dec mb-5">
                <Link to="/portfolio/" className="link-heading">
                  My latest work
                </Link>
              </h2>

              <Image
                fluid={portfolio.frontmatter.responsivePreview.childImageSharp.xs}
                alt={portfolio.frontmatter.title}
                className="d-md-none"
              />

              <h3 className="pt-4">
                <Link
                  to={`/portfolio${portfolio.fields.slug}`}
                  className="link-heading"
                >
                  {portfolio.frontmatter.title}
                </Link>
              </h3>
              <div className="desc">
                <p>
                  {portfolio.frontmatter.summary}
                </p>
                <p>
                  <Link to={`/portfolio${portfolio.fields.slug}`} className="link-action">
                    About this project
                  </Link>
                </p>
              </div>
            </Col>

            <Col md={7} lg={8} className="d-none d-md-block">
              <Link to={`/portfolio${portfolio.fields.slug}`}>
                <Image
                  fluid={[
                    {
                      ...portfolio.frontmatter.responsivePreview.childImageSharp.md,
                      media: `(min-width: 768px) and (max-width: 991px)`,
                    },
                    {
                      ...portfolio.frontmatter.responsivePreview.childImageSharp.lg,
                      media: `(min-width: 992px) and (max-width: 1199px)`,
                    },
                    {
                      ...portfolio.frontmatter.responsivePreview.childImageSharp.xl,
                      media: `(min-width: 1200px)`,
                    },
                  ]}
                  alt={portfolio.frontmatter.title}
                />
              </Link>
            </Col>
          </Row>
        </Container>
      </Section>

      <Section>
        <Container>
          <Row>
            <Col xs={12} lg={4} className="mb-4 mb-lg-0">
              <h2 className="heading-dec mb-4">
                <Link to="/playbook/" className="link-heading">
                  Playbook
                </Link>
              </h2>
              <p className="desc">
                Website and content how-to guides, battle-tested across many
                years and projects.
              </p>
            </Col>

            <Col xs={12} sm={6} lg={4} className="mb-4 mb-sm-0 pt-lg-5">
              <h3 className="h4 pt-lg-3">
                Building and running websites
              </h3>
              <nav>
                <ul className="list-unstyled">
                  <li className="mb-1">
                    <Link to="/playbook/google-analytics-setup/">
                      Google Analytics Setup
                    </Link>
                  </li>
                  <li className="mb-1">
                    <Link to="/playbook/sass-css-style-guide/">
                      Sass and CSS Style Guide
                    </Link>
                  </li>
                  {/*
                  <li>
                    <Link to="/uses/">
                      Tools I Use
                    </Link>
                  </li>
                  */}
                </ul>
              </nav>
            </Col>

            <Col xs={12} sm={6} lg={4} className="pt-lg-5">
              <h3 className="h4 pt-lg-3">
                Web content best practices
              </h3>
              <nav>
                <ul className="list-unstyled">
                  <li className="mb-1">
                    <Link to="/playbook/about-us-pages/">
                      About Us Pages
                    </Link>
                  </li>
                  <li className="mb-1">
                    <Link to="/playbook/contact-us-pages/">
                      Contact Us Pages
                    </Link>
                  </li>
                  <li className="mb-1">
                    <Link to="/playbook/press-media-pages/">
                      Press &amp; Media Pages
                    </Link>
                  </li>
                  <li>
                    <Link to="/playbook/web-content-guidelines/">
                      Web Content Guidelines
                    </Link>
                  </li>
                </ul>
              </nav>
            </Col>
          </Row>
        </Container>
      </Section>

      <Section>
        <Container>
          <Row>
            <Col xs={12} xl={4} className="mb-4 mb-xl-0">
              <h2 className="heading-dec mb-4">
                <Link to="/blog/" className="link-heading">
                  Blog
                </Link>
              </h2>
              <p className="desc">
                My latest ideas and lessons learned.
              </p>
            </Col>

            {data.blog.nodes.map(post => (
              <Col
                key={post.fields.slug}
                xs={12}
                md={6}
                xl={4}
                className="mb-4 mb-md-0 pt-xl-5"
              >
                <h3 className="h5 pt-xl-3">
                  <Link to={`/blog${post.fields.slug}`}>
                    {post.frontmatter.title}
                  </Link>
                </h3>
                <div className="desc">
                  <p>
                    {/*dateTime={post.isoDate*/}
                    <time>
                      {post.frontmatter.date}
                    </time>
                  </p>
                  <p>
                    {post.excerpt}
                  </p>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </Section>
    </Layout>
  )
}

export const query = graphql`
  query IndexQuery {
    site {
      siteMetadata {
        siteUrl
      }
    }
    avatar: file(relativePath: { eq: "chris-avatar.jpg" }) {
      childImageSharp {
        xs: fixed(width: 80, height: 80) {
          ...GatsbyImageSharpFixed
        }
        lg: fixed(width: 160, height: 160) {
          ...GatsbyImageSharpFixed
        }
        xl: fixed(width: 180, height: 180) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    portfolio: allMarkdownRemark(
      filter: { fields: { content_type: { eq: "portfolio" }}}
      sort: { fields: frontmatter___year, order: DESC }
      limit: 1
    ) {
      nodes {
        fields {
          slug
        }
        frontmatter {
          title
          summary
          responsivePreview: responsive_preview {
            childImageSharp {
              xs: fluid(maxWidth: 600, maxHeight: 495) {
                ...GatsbyImageSharpFluid
              }
              md: fluid(maxWidth: 420) {
                ...GatsbyImageSharpFluid
              }
              lg: fluid(maxWidth: 640) {
                ...GatsbyImageSharpFluid
              }
              xl: fluid(maxWidth: 768) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    blog: allMarkdownRemark(
      filter: {fields: {content_type: {eq: "blog"}}}
      sort: { fields: [frontmatter___date], order: DESC }
      limit: 2
    ) {

        nodes {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM D, YYYY")
            title
          }
        }

    }
  }
`

export default IndexPage
